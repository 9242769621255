import React, { useEffect } from 'react';
import Pvolve from '@pvolve/sdk';

import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { Container, Divider, Grid, Image } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import AuthPageWrap from 'src/components/auth/AuthPageWrap';
import LoginPasswordPage from 'src/components/auth/LoginPasswordPage';
import EmailForm from 'src/components/auth/EmailForm';
import SocialButton from 'src/components/auth/SocialButton';
import SEO from 'src/components/SEO';
import withLocation from 'src/components/withLocation';

import { validateEmail } from 'src/utils/form-utils';

const ContinueAuthPage = ({ search: { redirectUrl }, location: { state } }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // update location to remove query string from url
        if (window) {
            window.history.replaceState(
                {},
                '',
                window.location.href.split('?')[0]
            );
        }
    }, []);

    const onFormSubmit = async (values) => {
        // TODO move all of this to sagas
        const user = await Pvolve.api.account.findUserByEmail(values.email);
        const nextRoute = user.status === 'CONFIRMED' ? '/continue' : '/signup';

        navigate(nextRoute, {
            state: {
                email: values.email,
                firstName: user.given_name,
                redirectUrl: redirectUrl,
            },
        });
    };

    const hasEmail = !isEmpty(state?.email);

    return (
        <AuthPageWrap>
            {hasEmail ? (
                <LoginPasswordPage
                    email={state?.email}
                    firstName={state?.firstName}
                    redirectUrl={state?.redirectUrl}
                />
            ) : (
                <>
                    <SEO title="P.Volve Continue to Login or Signup" />
                    <Image
                        src="https://cdn.shopify.com/s/files/1/2379/5349/files/pvolve-logo.jpg"
                        size="small"
                        centered
                    />
                    <Container textAlign="center">
                        <p className="secondaryText margin-top--small">
                            P.volve workouts, classes, equipment & apparel.
                        </p>
                    </Container>
                    <div className="margin-top--large">
                        <Grid centered columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <SocialButton
                                        provider="SignInWithApple"
                                        icon="apple"
                                        label="Continue with Apple"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <SocialButton
                                        provider="Facebook"
                                        icon="facebook f"
                                        label="Continue with Facebook"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <SocialButton
                                        provider="Google"
                                        icon="google"
                                        label="Continue with Google"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Container textAlign="center">
                                        <p className="secondaryText">
                                            We won't post anything without your
                                            permission
                                        </p>
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Divider horizontal>OR</Divider>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>
                        </Grid>
                        <Formik
                            initialValues={{ email: '' }}
                            validate={validateEmail}
                            onSubmit={onFormSubmit}
                        >
                            {(formikProps) => <EmailForm {...formikProps} />}
                        </Formik>
                        <Container
                            textAlign="center"
                            className="margin-top--small"
                        >
                            <p className="secondaryText">
                                By proceeding you agree to our{' '}
                                <a href="https://www.pvolve.com/pages/terms-of-service">
                                    Terms of Service
                                </a>
                            </p>
                        </Container>
                    </div>
                </>
            )}
        </AuthPageWrap>
    );
};

export default withLocation(ContinueAuthPage);
