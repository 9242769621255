import React from 'react';

import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';

import {
    getErrorMessage,
    hasAllValues,
    FORM_MESSAGES,
} from 'src/utils/form-utils';
import { redirect } from 'src/utils/url-utils';

const PasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const toggleShowPassword = () =>
        setShowPassword((showPasswordState) => !showPasswordState);

    return (
        <Form onSubmit={handleSubmit} size="big">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <h2 style={{ color: 'red' }}>{errors.general}</h2>
                        <input
                            type="hidden"
                            id="login-email"
                            name="email"
                            value={values.email}
                            aria-label="Email (hidden form input)"
                        />
                        <Form.Field>
                            <Form.Input
                                action={
                                    <Button
                                        type="button"
                                        icon={`eye${
                                            showPassword ? ' slash outline' : ''
                                        }`}
                                        onClick={toggleShowPassword}
                                    />
                                }
                                label={{
                                    children: 'Password',
                                    htmlFor: 'login-password',
                                    className: values.password ? '' : 'hidden',
                                }}
                                id="login-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={
                                    errors.password && touched.password
                                        ? getErrorMessage(errors.password)
                                        : false
                                }
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                !hasAllValues(values) ||
                                !isEmpty(errors)
                            }
                            className="margin-bottom--medium"
                            primary
                            fluid
                        >
                            LOG IN
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setSubmitting, setFieldError }) => {
    const onFailure = (message) => setFieldError('general', message);
    const onSuccess = () => redirect(props.redirectUrl);
    const onFulfill = () => setSubmitting(false);

    props.dispatch(
        Actions.auth.login.trigger({
            ...formValues,
            onFailure,
            onSuccess,
            onFulfill,
        })
    );
};

const validate = (values) => {
    const error = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    }

    return error;
};

const FormikPasswordForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => ({ email: props.email, password: '' }),
})(PasswordForm);

export default connect()(FormikPasswordForm);
