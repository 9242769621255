import { isHttpsUri, isWebUri } from 'valid-url';
import { navigate } from 'gatsby';

export const isPvolveDomain = (url) => {
    return !!isWebUri(url) && !!new URL(url).hostname.match(/pvolve.com$/);
};

export const isMuxDomain = (url) => {
    return (
        !!isHttpsUri(url) && !!new URL(url).hostname.match(/stream.mux.com$/)
    );
};

// This method exists in case we add other criteria for mux urls in the future
export const isValidMuxUrl = (url) => {
    return isMuxDomain(url);
};

// This method exists in case we add other criteria to redirect urls in the future
export const isValidRedirectUrl = (redirectUrl) => {
    return isPvolveDomain(redirectUrl);
};

export const redirect = (url) => {
    if (window && isValidRedirectUrl(url)) {
        window.location.replace(url);
    } else {
        navigate('/');
    }
};
