/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pvolve from '@pvolve/sdk';

import { Button, Icon } from 'semantic-ui-react';

import withLocation from '../../components/withLocation';

const SocialButton = ({ label, icon, provider, location }) => {
    const [link, setOauthLink] = useState();

    /**
     * useEffect: callback function argument can not be async.
     *   - async functions return promises.
     *   - useEffect expects an unmount callback func returned
     * useEffect:
     *   - empty [] 2nd argument equates to componentDidMount lifecycle only
     */
    useEffect(() => {
        async function initOauthLink() {
            const oauthLink = await Pvolve.api.cognito.oauthProviderRedirectUrl(
                location.href,
                { identity_provider: provider }
            );
            setOauthLink(oauthLink);
        }

        initOauthLink();
    }, []);

    return (
        <Button
            href={link}
            disabled={!link}
            labelPosition="left"
            basic
            fluid
            icon
        >
            <Icon name={icon} />
            {label}
        </Button>
    );
};

SocialButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};

export default withLocation(SocialButton);
