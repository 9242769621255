import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { Container, Icon, Image } from 'semantic-ui-react';

import PasswordForm from 'src/components/auth/PasswordForm';
import SEO from 'src/components/SEO';
import AuthPageWrap from 'src/components/auth/AuthPageWrap';

const LoginPasswordPage = ({ firstName, email, redirectUrl }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // we want to make sure to clear the current state
        // so that if a user hits refresh, they go back to the
        // initial continue screen to enter their email
        if (window) {
            window.history.replaceState(
                {},
                '',
                window.location.href.split('?')[0]
            );
        }
    }, []);

    return (
        <AuthPageWrap>
            <SEO title="P.Volve Login" />
            <Image
                src="https://cdn.shopify.com/s/files/1/2379/5349/files/pvolve-logo.jpg"
                size="small"
                centered
            />
            <Container textAlign="center">
                <p className="secondaryText margin-top--small">
                    {firstName
                        ? `Welcome back, ${firstName}!`
                        : 'Welcome back!'}
                    <br />
                    Let's get you to your workout.
                </p>
            </Container>
            <div className="margin-top--large">
                <PasswordForm email={email} redirectUrl={redirectUrl} />
            </div>
            <Container textAlign="center">
                <p className="margin-top--xlarge">
                    <Link to="/loginHelp">
                        <b>I forgot my password. ¯\_(ツ)_/¯</b>
                    </Link>
                </p>
                <p className="margin-top--xlarge">
                    <Link to="/continue">
                        <Icon name="arrow left" />
                        <b>Wrong email. Go back.</b>
                    </Link>
                </p>
            </Container>
        </AuthPageWrap>
    );
};

export default LoginPasswordPage;
