import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { getErrorMessage, hasAllValues } from 'src/utils/form-utils';

const EmailForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    buttonLabel,
}) => (
    <Form onSubmit={handleSubmit} size="large" noValidate>
        <Grid centered columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <Form.Input
                        id="login-email"
                        name="email"
                        type="email"
                        label={{
                            children: 'Email',
                            htmlFor: 'login-email',
                            className: values.email ? '' : 'hidden',
                        }}
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={
                            errors.email && touched.email
                                ? getErrorMessage(errors.email)
                                : false
                        }
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        type="submit"
                        disabled={
                            isSubmitting ||
                            !hasAllValues(values) ||
                            !isEmpty(errors)
                        }
                        className="margin-top--small"
                        primary
                        fluid
                    >
                        {buttonLabel}
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Form>
);

EmailForm.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    buttonLabel: PropTypes.string,
};

EmailForm.defaultProps = {
    buttonLabel: 'CONTINUE WITH EMAIL',
};

export default EmailForm;
